import React from "react"
import styled from "styled-components"

import GlobalStateProvider from "../context/provider"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentWrapper from "../styles/contentWrapper"

const NotFoundPage = () => {
  const globalState = {
    isIntroDone: true,
    darkMode: false,
  }

  return (
    <GlobalStateProvider initialState={globalState}>
      <Layout ignoreHeaderFooter={true}>
        <SEO
          title="20240909 tba"

        />
        <Header />
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: 'yellow' }}>
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScpUaFFdMUWbRPBVT01KrvF_GoDHJnVUnGoNa5amSpd-ZLHxA/viewform?embedded=true" width='80%' height='800' frameBorder="0" marginHeight="0" marginWidth="0">載入中…</iframe>
        </div>
      </Layout>
      <Footer />
    </GlobalStateProvider>
  )
}

const Header = () => {
  return (
    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: 25, backgroundColor: '#DAD4B5' }}>
    </div>
  )
}

const Footer = () => {
  return (
    <div style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', height: 25, backgroundColor: '#DAD4B5' }}>
    </div>
  )
}


export default NotFoundPage
